<!--The content below is only a placeholder and can be replaced.-->

<div class="ui inverted top fixed menu">
  <div class="ui container">
    <a routerLink="/" class="header item">
      <!-- <img class="ui mini image" src="/assets/cody_logo.png"> -->
      &nbsp;
      Customer Portal
    </a>
    <a class="item" *ngIf="!(authService.authState$ | async)?.isAuthenticated" (click)="login()">Login</a>
    <a id="messages-button" class="item" *ngIf="(authService.authState$ | async)?.isAuthenticated" routerLink="/messages"><i aria-hidden="true" class="mail outline icon"></i>Messages</a>
    <a id="profile-button" class="item" *ngIf="(authService.authState$ | async)?.isAuthenticated" target="_blank" href="https://okta817.zendesk.com">Zendesk</a>
    <a id="profile-button" class="item" *ngIf="(authService.authState$ | async)?.isAuthenticated" routerLink="/profile">Profile</a>
    <a id="logout-button" class="item" *ngIf="(authService.authState$ | async)?.isAuthenticated" (click)="logout()">Logout</a>
  </div>
</div>

<div class="ui text container" style="margin-top: 7em;">
  <router-outlet></router-outlet>
</div>
