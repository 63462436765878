<div *ngIf="error" class="error">
  {{ error }}
</div>

<h1 class="ui header">Customer Portal Home</h1>
<div *ngIf="!isAuthenticated">
  <p>If you're viewing this page then you have successfully started this application, but you are not logged in.</p>
  <p>When you click the login button below, you will be redirected to the login page on your Okta org. After you authenticate,
    you will be returned to this application with an ID Token and Access Token. These tokens will be stored in local storage
    and can be retrieved at a later time.</p>
  <button id="login-button" class="ui primary button" role="button" (click)="login()">Login</button>
</div>

<div *ngIf="isAuthenticated && userName">
  <p>Welcome back, {{ userName }}!</p>
  <p>
    You have successfully authenticated against your SEC org, and have been redirected back to this application.  You now have an ID Token and access token in local storage.
    Visit the <a href="/profile">My Profile</a> page to take a look inside the ID Token.
  </p>
  <!-- <h3>Next Steps</h3>
  <p>Currently this application is a stand-alone front end application.  At this point you can use the access token to authenticate yourself against resource servers that you control.</p>
  <p>This sample is designed to work with one of our resource server examples.  To see access token authentication in action, please download one of these resource server examples:</p>
  <ul>
    <li *ngFor="let example of resourceServerExamples"><a href="{{example.url}}">{{example.label}}</a></li>
  </ul>
  <p>Once you have downloaded and started the example resource server, you can visit the <a href="/messages">My Messages</a> page to see the authentication process in action.</p> -->
  <!-- <img src="../../assets/SEC_new_logo.png"> -->
</div>
