<h1 class="ui header">
  <i aria-hidden="true" class="mail outline icon"></i> My Messages
</h1>
<div *ngIf="failed" class="ui error message">
  <div class="content">
    <div class="header">Failed to fetch messages. Please verify the following:</div>
    <ul class="list">
      <li class="content">You've downloaded one of our resource server examples, and it's running on port 8000.</li>
      <li class="content">Your resource server example is using the same Okta authorization server (issuer) that you have configured this Angular
        application to use.</li>
    </ul>
  </div>
</div>

<div *ngIf="messages.length">
  <p>This component makes a GET request to the resource server example, which must be running at
    <code>localhost:8000/api/messages</code>
  </p>
  <p>It attaches your current access token in the
    <code>Authorization</code> header on the request, and the resource server will attempt to authenticate this access token. If the token is valid
    the server will return a list of messages. If the token is not valid or the resource server is incorrectly configured,
    you will see a 401
    <code>Unauthorized response</code>.</p>
  <p>This route is protected with the
    <code>OktaAuthGuard</code> component, which will ensure that this page cannot be accessed until you have authenticated and have an access token
    in local storage.</p>
  <table class="ui table">
    <thead>
      <tr>
        <th>Date</th>
        <th>Message</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let message of messages; index as i" id="message-{{i}}">
        <td>{{message.date}}</td>
        <td>{{message.text}}</td>
      </tr>
    </tbody>
  </table>
</div>
